<template>
  <div 
    class="content"
  >
    <div class="content-wrap brand-font-bold">
      <h1>You scored</h1>
      <h2>{{ score }}%</h2>

      <div role="navigation">
        <router-link
          :to="`/`"
          class="btn primary"
          role="button"
        >
          Finish Game
        </router-link>
  
        <router-link
          :to="scenarioLink"
          class="btn"
          role="button"
        >
          Play again
          <ArrowRight />
        </router-link>
      </div>
    </div>
    <div
      class="text-toggle"
      @click="increaseFontSize"
    >
      <IncreaseFontSize />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ArrowRight from '@/components/icons/ArrowRight.vue'
import Manifest from '@/content/scenarios/manifest.json'
import IncreaseFontSize from '@/components/icons/IncreaseFontSize.vue'

export default {
  name: 'Score',
  components: {
    ArrowRight,
    IncreaseFontSize
  },
  data() {
    return {
      // Get the score from the VUEX store
      score: this.$store.getters.totalScore
    };
  },
  computed: {
    scenarioLink() {
      // Set to question 1
      const questionId = 1

      // Get the last played scenario from storage, if there is any and remove it if so
      const lastScenario = parseInt(localStorage.getItem('last-scenario')) ?? null
      localStorage.removeItem('last-scenario')

      // Get the list of scenarios from the manifest
      const scenarios = Manifest.scenarios

      // Filter them removing the last played scenario
      let filteredScenarios = scenarios.filter(item => item !== lastScenario)

      // If there are no scenarios left somehow then remove the filter
      if (filteredScenarios.length === 0) {
        filteredScenarios = scenarios
      }

      // Randomly pick an alternative scenario by index
      const scenarioId = filteredScenarios[Math.floor(Math.random() * filteredScenarios.length)] 

      // Redirect
      return `/scenario/${scenarioId}/question/${questionId}`
    }
  },
  mounted() {
    // On page mount reset the score
    this.$store.commit('resetScore')
  },
  methods: {
    increaseFontSize() {
      if (document.body.classList.contains('increased-font-size')) {
        // Do something
        document.body.classList.remove('increased-font-size')
      } else {
        document.body.classList.add('increased-font-size')
      }
    },
  }
}
</script>

<style scoped>
.content-wrap {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  max-width: 60% !important;
  margin: auto;
  text-align: center;
}

.content {
  height: 100vh;
}

h1 {
  font-size: 168px;
  margin: 0;
  margin-bottom: .25em;
  line-height: 1;
}

h2 {
  font-size: 417px;
  line-height: 1;
  margin: 0;
  color: #FEC000;
  margin-bottom: .5em;
}

.btn {
  font-size: 126px;
  display: block;
  color: #fff;
  text-decoration: none;
  background: #E1001E;
  padding: .6em 1em;
  border-radius: 2em;
  position: relative;
  margin: .6em auto;
}

.btn.primary {
    background: #FEC000;
    color: #E1001E;
}

.btn svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1em;
  width: 1em;
  height: auto;
}
</style>
<style>
.btn svg * {
  fill: white;
}
</style>
